.accordion {
    background-color: #181818;
    filter: none;
}

h2 {
    color: #EEF3FA;
    font-size: 20px;
    font-family: 'Coolvetica';
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.summary {
    position: relative;
    padding: 10px 0px;
    filter: none;


    button {
        &:hover {
            background-color: #1B1C1E !important;
        }
    }
   
}


.details {
    margin-left: 15px;
}
