.page {
    width: 100%;
    height: 100vh;
    position: relative;

}

.top-tags {
    bottom: auto;
    top: 1000px;
    left: 900px;
    animation: fadeIn 1s 1.2s backwards;
}

.right-tags {
    position: absolute;
    margin-top: -100px;
    left: 250px;
    opacity: 0.6;
    animation: fadeIn 1s 1.9s backwards;
}

.middle-tags {
    position: absolute;
    margin-top: 300px;
    left: auto;
    right: 400px;
    opacity: 0.6;
    animation: fadeIn 1s 1.3s backwards;
}

.bottom-tag-html {
    margin-left: 400px;
    margin-top: 60vh;
    animation: fadeIn 1s 2s backwards;
}


.tags {
    color: #66FCF1;
    opacity: 0.6;
    position: absolute;
    // bottom: 0;
    // left: 120px;
    top: 200px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
}



.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: relative;
    opacity: 0;
    margin: 0 auto;
    z-index: 0;
    transform-style: preserve-3d;
    animation: fadeIn 0s forwards;
    // animation-delay: 1s;
    background: linear-gradient(270deg, rgb(50, 50, 50) 20%, rgb(0, 0, 0) 90.4%);
    // background: rgb(255,104,106);
    // background: linear-gradient(90deg, rgba(255,104,106,0.6) 0%, rgba(255,201,103,0.6) 70%);
    // filter: url(#noiseFilter);
 }

.about-page,
.contact-page,
.projects-page {
    .text-zone {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 35%;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #EEF3FA;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
            filter: none;
            text-align: center;

            &::before {
                // content: 'hello!';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                color: #66FCF1;
                position: absolute;
                margin-top: -200px;
                left: 500px;
                opacity: 0.6;
                line-height: 18px;
                animation: fadeIn 1s 2s backwards;
            }

            &::after {
                // content: 'greetings';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                color: #66FCF1;
                line-height: 18px;
                position: absolute;
                left: 650px;
                bottom: -100px;
                opacity: 0.6;
                animation: fadeIn 1s 1.4s backwards;
            }
        }


        p {
            font-size: 13px;
            color: #EEF3FA;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;
            text-align: justify;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: #66FCF1;
        }
    }

}