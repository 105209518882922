.home-page {

    .text-zone {
        position: relative;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {

        color: #EEF3FA;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        // &::before {
        //     content: 'hey there';
        //     font-family: 'La Belle Aurore';
        //     color: #66FCF1;
        //     font-size: 18px;
        //     position: absolute;
        //     margin-top: -100px;
        //     left: 15px;
        //     opacity: 0.6;
        // }

        // &::after {
        //     content: 'hi!';
        //     font-family: 'La Belle Aurore';
        //     color: #66FCF1;
        //     font-size: 18px;
        //     position: relative;
        //     margin-top: -80px;
        //     margin-left: 80px;
        //     animation: fadeIn 1s 1.7s backwards;
        //     opacity: 0.6;
        // }
        

        // img {
        //     width: 32px;
        //     margin-left: 20px;
        //     opacity: 0;
        //     height: auto;
        //     animation: rotateIn 1s linear both;
        //     animation-delay: 1.4s;
        // }
    }

    h2 {
        color: #EEF3FA;
        margin-top: 20px;
        font-weight: 400;
        font-size: 14px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #EEF3FA;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #66FCF1;
        border-radius: 4px;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background-color: #66FCF1;
            color: #333;
        }
    }

    .profile-picture {
        height: 500px;
        position: absolute;
        right: 18%;
        top: 200px;
        animation: fadeIn 2s 3s backwards;
    }
}